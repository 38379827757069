div[id^="data-side-panel-"] {
    position: fixed;
    top: 48px;
    bottom: 0;
    right: 0;
    width: 85%;
    z-index: 100;
    overflow-y: hidden;
    overflow-x: hidden;
    display: none;

    &::before {
        content: '';
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }

    > .card {
        height: calc(100vh - 48px);

        .side-panel-body {
            overflow-y: auto;
            background-color: #fff;
        }
    }

    .side-panel-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 110;
        overflow-y: scroll;
        overflow-x: hidden;
        display: none;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

