#nc-menu-overview {
    .overview-search {
        input {
            position: relative;

            &:valid {
                &::after {
                    top: 0;
                    right: 0;
                    content: "\f00d";
                }
            }
        }
    }

    .overview-item {
        .card-header {
            .card-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    overflow: unset;
                    white-space: unset;
                }
            }
        }

        .card-body {
            max-height: 180px;
            overflow: auto;

            table {
                th, td {
                    padding: 0.4rem;
                    white-space: nowrap;
                }
            }

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

}
