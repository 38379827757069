@import "~@noardcode/compass-core/resources/sass/app";
@import "~@noardcode/compass-side-panels";
@import "~@noardcode/compass-media-library";

@import "../../vendor/noardcode/laravel-tables/resources/sass/tables";

header {
    display: flex;
    justify-content: flex-start;   /* adjustment */
    position: relative;            /* new */
    width: 100%;
}

.logo {
    flex: 0 1 auto;
    width: 150px;
    height: 100px;
    position: absolute;             /* new */
    left: 50%;
    transform: translateX(-50%);
}

.buttons {
    flex: 0 1 auto;
    width: 100px;
    height: 100px;
    margin-left: auto;              /* new */
}
