#sidebar {
    .nav-link[data-toggle].collapsed:after {
        content: "▾";
    }

    .nav-link[data-toggle]:not(.collapsed):after {
        content: "▴";
    }
}

#nav-sidebar {
    padding: 0;
    height: calc(100vh - 3rem - -45px);
    position: fixed;

    .nav-item {
        margin: 0;
    }

    .nav-link > .nav-link {
        width: 90%;
    }

    .btn-group {
        border: none;

        .btn {
            color: rgba(0, 0, 0, 0.5);
            padding: 0;
            border: none;

            &:hover, &:focus {
                border: none;
                box-shadow: none;
            }

            &.dropdown-toggle {
                padding-left: 0.35rem;
            }
        }
    }

    .nav-link.active {
        color: $gray-800 !important;
        background-color: $gray-400;
    }

    .nav-dropdown-items {
        margin-left: 1.4rem;
        border-left: 1px solid $gray-700;
        display: none;

        .nav-link {
            color: $gray-700 !important;
            margin-right: 1px;

            &.active {
                background-color: lighten($gray-300, 0.5);
            }
        }

        &.active {
            display: block;
        }
    }
}
