// Sortable SASS
#menu-sorting {
    .nested-sortable {
        cursor: grab;

        li {
            margin-bottom: 5px;
            padding-right: 0;
            padding-bottom: 0;

            > ul > li {
                background: #ccc !important;

                > ul > li {
                    background: #b3b3b3 !important;
                }
            }
        }

        ul {
            margin-bottom: 0;
            padding-inline-start: 0;
        }

        .locked {
            background: #F5CE6E !important;
            border: 2px solid rgba(0, 0, 0, 0.125);
            color: #000;
            cursor: default;
            padding-bottom: 0;
        }

        .right-icons-items {
            display: flex;
            flex-direction: row-reverse;
            color: #000;
            width: calc(93% + 5px);
            margin-top: 3px;
            padding-right: 1.25rem;
        }

        .title-icons {
            display: flex;
            padding-bottom: 5px;
            margin-top: -5px;
        }
    }

    .list-group {
        border-radius: 0;

        .list-group-item {
            color: #000;
            background: #fff;
            padding-bottom: 0;

            label {
                margin: 0;
            }
        }
    }
    .list-group-item + .list-group-item {
        border-top-width: 1px;
    }
}

/* Collapse menu item */
.menu {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: #fff;
    overflow: hidden;
    bottom: 5px;
    background: #fff;
    padding: .75rem 1.25rem;
    top: 0;

    .menu-content {
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height .35s;
        -o-transition: max-height .35s;
        transition: max-height .35s;
        color: #000;

        p {
            margin: 1em;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    label {
        position: relative;
        line-height: 1;
        padding: 0;
        margin: 0;
    }

    /* Plus icon */
    label::after {
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
        font-size: 30px;
        margin-top: -7px;
        margin-right: 7px;
    }

    .active + label::after {
        content: "\2212" !important;
    }

    input[type=checkbox] + label::after {
        content: "+";
    }

    input[type=checkbox]:checked + label::after {
        transform: rotate(360deg);
    }

    input[type=radio]:checked + label::after {
        transform: rotateX(180deg);
    }
}
