@import "~cropperjs";
@import "cropper";

.media-library-items {
    .row > div {
        overflow: hidden;
        padding: 0.5rem;
        color: $gray-800;
        text-decoration: none;

        .media-folder-link {
            color: $gray-800;

            &:hover {
                color: $gray-900;
            }
        }

        &:hover {
            color: $gray-900;
            background-color: $gray-400;
            text-decoration: none;

            .btn-group-vertical {
                top: 0.5rem;
                left: 0.5rem;
                display: inline-block !important;
            }
        }

        .btn-group-vertical {
            min-width: 26px;
            width: 20%;

            .btn {
                padding: 0.25rem;
            }
        }

        table {
            height: 100%;

            td, th {
                vertical-align: middle;
                text-align: center;
            }

            td {
                height: 100px;
                max-height: 100px;

                img {
                    max-height: 100px;
                }
            }

            th {
                font-size: 0.8em;
                text-transform: capitalize;
                text-decoration: none;
            }
        }
    }

    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        z-index: 110;
        overflow-y: scroll;
        overflow-x: hidden;
        display: none;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

